<template>
  <div>
    <TableComponent :fields="fields" :items="messages" :busy="loading">
      <template #cell(scheduled_at)="slotProps">
        <router-link :to="`history/${slotProps.item.id}`" class="text-primary link-out-side">
          {{ slotProps.data }}
        </router-link>
      </template>
      <template #cell(direction)="slotProps">
        <BadgeComponent variant="info" v-if="slotProps.data == 'outbound'">
          {{ $t('generic-str.send-2') }}
        </BadgeComponent>
        <BadgeComponent variant="success" v-if="slotProps.data == 'inbound'">Resposta
        </BadgeComponent>
      </template>
      <template #cell(body)="slotProps">
        <template v-tooltip.top="`${slotProps.data}`">
          {{ slotProps.data }}
        </template>
      </template>
      <template #cell(status)="slotProps">
        <BadgeComponent variant="light" v-if="slotProps.data == 'accepted'">
          {{ $tc('generic-str.status.lbl-pending', 1) }}
        </BadgeComponent>
        <BadgeComponent variant="light" v-if="slotProps.data == 'scheduled'">
          {{ $tc('generic-str.status.lbl-scheduled', 1) }}
        </BadgeComponent>
        <BadgeComponent variant="light" v-if="slotProps.data == 'queued'">
          {{ $tc('generic-str.status.lbl-queue', 1) }}
        </BadgeComponent>
        <BadgeComponent variant="dark" v-if="slotProps.data == 'sending'">
          {{ $tc('generic-str.status.lbl-sending', 1) }}
        </BadgeComponent>
        <BadgeComponent variant="info" v-if="slotProps.data == 'sent'">
          {{ $tc('generic-str.status.lbl-sent', 1) }}
        </BadgeComponent>
        <BadgeComponent variant="danger" v-if="slotProps.data == 'failed'"
                        v-tooltip.top="`${$t('sms.infos.filters.status.lbl-error')} ${slotProps.item.error_code}: ${slotProps.item.error_message}`">
          {{ $tc('generic-str.status.lbl-failure', 1) }}
        </BadgeComponent>
        <BadgeComponent variant="success" v-if="slotProps.data == 'delivered'">
          {{ $tc('generic-str.status.lbl-delivered', 1) }}
        </BadgeComponent>
        <BadgeComponent variant="danger" v-if="slotProps.data == 'undelivered'"
                        v-tooltip.top="`${$t('sms.infos.filters.status.lbl-error')} ${slotProps.item.error_code}: ${slotProps.item.error_message}`">
          {{ $tc('generic-str.status.lbl-unavailable', 1) }}
        </BadgeComponent>
        <BadgeComponent variant="info" v-if="slotProps.data == 'received'">
          {{ $tc('generic-str.status.lbl-response', 1) }}
        </BadgeComponent>
      </template>
    </TableComponent>
    <PaginationComponent :totalPages="pages" v-model="form.page" @change="fetch"/>
  </div>
</template>

<script>
import PaginationComponent from '@/components/PaginationComponent.vue';
import TableComponent from '@/components/TableComponent.vue';
import BadgeComponent from '@/components/ui/BadgeComponent.vue';
import moment from 'moment-timezone';

export default {
  name: 'SmsHistoryTab',
  components: {
    BadgeComponent,
    TableComponent,
    PaginationComponent,
  },
  props: {
    filters: {
      type: Object,
    },
    startDate: {
      type: String,
    },
    endDate: {
      type: String,
    },
  },
  data() {
    return {
      fields: [
        { key: 'scheduled_at', formatter: (value) => moment(value).format('DD/MM/YYYY HH:mm:ss') },
        'direction',
        'from',
        'to',
        'body',
        'segments',
        'status',
      ],
      options: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        maxSpan: {
          days: 31,
        },
        locale: {
          format: 'DD/MM/YYYY',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
      loading: true,
      messages: [],
      form: {
        id: '',
        status: '',
        from: '',
        to: '',
        scheduled_at: {
          gte: '',
          lte: '',
        },
        order_by: ['scheduled_at', 'desc'],
        page: 1,
      },
      pages: 1,
      exporting: false,
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch(page = 1) {
      this.loading = true;
      if (this.$route.params.campaignId) {
        this.filters.campaign_id = this.$route.params.campaignId;
      }
      this.$store
        .dispatch('fetchSmsMessages', {
          filter: {
            ...this.filters,
            scheduled_at: {
              gte: this.startDate,
              lt: this.endDate,
            },
          },
          page,
        })
        .then((response) => {
          this.messages = response.data;
          this.pages = response.last_page;
        })
        .catch((error) => {
          this.$toast.show({
            title: this.$t('app.error'),
            content: error.message,
            type: 'danger',
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    exportData(format) {
      this.loading = true;

      this.$store
        .dispatch('exportSmsMessages', {
          filter: {
            ...this.filters,
            scheduled_at: {
              gte: this.startDate,
              lt: this.endDate,
            },
          },
          format,
        })
        .then(() => {
          this.$toast.show({
            title: this.$t('generic-str.success'),
            content: this.$t('warnings.toast.report'),
            type: 'success',
          });
          this.$router.push({ name: 'SmsReportExports' });
        })
        .finally(() => {
          this.fetched = true;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.btn-search-id {
  height: 35px;
}

.btn-export {
  margin-left: 1px;
  padding: 8px 20px !important;
}

.m-top-10 {
  margin-top: 10px;
}

/* Rebranding */
.rebranding .card.borderless {
  box-shadow: unset !important;
}

.form-group {
  .icon_btn {
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid var(--form-border);
    border-left-color: transparent;
    border-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    span {
      color: var(--gray-font-color);
    }
  }
}

.darkmode .form-group .icon_btn {
  background-color: var(--form-input);
  border-color: var(--form-border);
  border-left-color: transparent;
}

.icon-success {
  color: #6eda2c;
}

.icon-failed {
  color: #ff0000;
}
</style>
